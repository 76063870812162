<template>
  <div>
    <v-dialog click v-model="storesDialogVisible" width="500">
      <StoresResultLst @storeSlected="onStoreSelected" @cancel="onStoreCancel" :stores="stores"></StoresResultLst>
    </v-dialog>
    <v-card :loading="isLoading">
      <v-list-item three-line>
        <v-list-item-content>
          <v-list-item-title class="headline">Chi tiết ca làm việc</v-list-item-title>
          <v-list-item-subtitle v-if="shift.checkin_time">{{shift.user_fullname}} - {{shift.checkin_time|timeShiftName}}</v-list-item-subtitle>
          <v-list-item-subtitle v-else>{{shift.user_fullname}} - {{shift.w_create_at|timeShiftName}}</v-list-item-subtitle>
          <v-list-item-title v-if="shift.checkout_time" style="color:#004D40">
            Kết quả:
            <i class="far fa-clock"></i> Giờ làm: {{shift.w_hours_diff}} --
            <i class="fas fa-clock"></i> Thực tế: <template v-if="shift.w_hours_ad_set===null">
                {{shift.w_hours_diff}}
            </template>
            <template v-else>
                {{shift.w_hours_ad_set}}
            </template>
          </v-list-item-title>
          <v-list-item-title style="color:#004D40" v-else-if="shift.checkin_time&&!shift.checkout_time">
               Bạn đã làm được:
            <i class="far fa-clock"></i> {{shift.w_hours_diff}}
          </v-list-item-title>
        </v-list-item-content>
        <!-- <v-btn type="error">Xóa</v-btn> -->
      </v-list-item>
      <v-divider></v-divider>
      <div style="padding:10px">
        <v-row>
          <v-col cols="6">
            <v-card style="    height: 100%;" class="mx-auto" outlined>
              <v-list class="transparent">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title style="color:#009688 " class="headline mb-1 line-break">
                      <i class="fas fa-sign-in-alt"></i>
                      {{shift.checkin_time|toDMYHM}}
                    </v-list-item-title>
                    <v-list-item-subtitle
                      v-if="shift.checkin_time"
                      class="line-break"
                    ><span> Tại: {{shift.check_in_store_name}} - Khoảng cách: {{shift.checkin_distance|numberF}}m </span>

                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <!-- <v-card-actions v-if="!shift.checkout_time">
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  v-if="!shift.checkin_time"
                  @click="checkinClick"
                  small
                >CheckIn</v-btn>
                <v-btn color="warning" v-else @click="checkinClick" small>CheckIn Lại</v-btn>
              </v-card-actions> -->
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card style="    height: 100%;" class="mx-auto" outlined>
              <v-list class="transparent">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title style="color:#01579B" class="headline mb-1 line-break">
                      <i class="fas fa-sign-out-alt"></i>
                      {{shift.checkout_time|toDMYHM}}
                    </v-list-item-title>
                    <v-list-item-subtitle
                      v-if="shift.checkout_time"
                      class="line-break"
                    ><span v-if="shift.checkout_store_id>0" > Tại: {{shift.check_out_store_name}} - Khoảng cách: {{shift.checkout_distance|numberF}}m </span>
                    <span v-else style="color:orange "> Tại: {{shift.check_out_store_name}} - Khoảng cách: {{shift.checkout_distance|numberF}}m </span></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <!-- <v-card-actions v-if="shift.checkin_time&&!shift.checkout_time">
                <v-spacer></v-spacer>

                <v-btn color="primary" @click="checkoutClick" small>CheckOut</v-btn>
              </v-card-actions> -->
            </v-card>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-text-field :readonly="!shift.is_owner" v-model="shift.w_em_note" dense label="Ghi chú của nhân viên" outlined></v-text-field>
        <v-divider></v-divider>
        <v-text-field
          v-model="shift.w_hours_ad_set"
          :readonly="!shift.is_admin"
          type="number"
          dense
          label="Thời gian làm việc thực tế"
          outlined
        ></v-text-field>
        <v-text-field :readonly="!shift.is_admin" v-model="shift.w_admin_note" dense label="Ghi chú của quản lý" outlined></v-text-field>
      </div>
      <v-divider></v-divider>
      <v-card-actions style="padding:0 20px 20px 20px">
          <v-btn v-if="!shift.checkin_time&&!shift.checkout_time" @click="deleteWorkShipClick" :loading="isLoading" color="error">Xóa ca</v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="updateWorkShipClick" :loading="isLoading" color="primary">Lưu lại</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>

import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import {
  getWorkShiftInfo,
  checkin,
  checkout,
  deleteWorkShift,
  updateWorkShift
} from "@/api/workshifts";
import { findStoreByLocation } from "@/api/stores";
import { getCurrentLocation } from "@/utils/location";
import StoresResultLst from "@/views/pages/admin/store/components/StoresResultLst.vue";
import { showToast } from "@/utils/index.js";
export default {
  created() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Ca làm việc", route: "/workshifts/" },
      { title: "Chi tiết", route: this.sId }
    ]);

    this.fetchData();

  },
  components: {
    StoresResultLst
  },
  methods: {
      async deleteWorkShipClick(){
          var confirm = await this.$confirm(
          "Bạn có chắc muốn xóa ca làm việc này?",
          { color: "warning", title: "Warning" }
        );
        if (!confirm) return;
        deleteWorkShift({id:this.sId}).then(()=>{
            window.history.back();
        })
      },
    updateWorkShipClick() {
      this.isLoading = true;
      updateWorkShift(this.shift).then(() => {
        this.isLoading = false;
        showToast(this, "success", "Đã lưu");
      });
    },
    async onStoreSelected(store) {
      this.storesDialogVisible = false;
      var location = await getCurrentLocation(this);
      if (this.state == "checkin") {
        this.isLoading = true;
        checkin({
          shift_id: this.sId,
          store_id: store.id,
          location: location
        })
          .then(res => {
            this.isLoading = false;
            res.data.is_admin = this.shift.is_admin;
            res.data.is_owner = this.shift.is_owner;
            this.shift = res.data;
          })
          .catch(() => {
            this.isLoading = false;
          });
      }
      if (this.state == "checkout") {
        this.isLoading = true;
        checkout({
          shift_id: this.sId,
          store_id: store.id,
          location: location
        })
          .then(res => {
            this.isLoading = false;
            res.data.is_admin = this.shift.is_admin;
            res.data.is_owner = this.shift.is_owner;
            this.shift = res.data;
          })
          .catch(() => {
            this.isLoading = false;
          });
      }
    },
    onStoreCancel() {
      this.storesDialogVisible = false;
    },
    // async checkinClick() {
    //   if (this.shift.checkin_time) {
    //     var confirm = await this.$confirm(
    //       "Bạn có chắc muốn Checkin lại, thời gian Checkin sẽ được cập nhật, bạn sẽ không thể hoàn tác",
    //       { color: "warning", title: "Warning" }
    //     );
    //     if (!confirm) return;
    //   }
    //   this.isLoading = true;
    //   var location = await getCurrentLocation(this);

    //   var res = await findStoreByLocation({
    //     type:'in',
    //     location: location
    //   });
    //   this.state = "checkin";
    //   this.stores = res.data;
    //   this.isLoading = false;
    //   this.storesDialogVisible = true;
    // },
    // async checkoutClick() {
    //   var confirm = await this.$confirm(
    //     "Bạn có chắc muốn Checkout, ca làm việc sẽ hoàn thành, bạn sẽ không được Checkout lại.",
    //     { color: "success", title: "Warning" }
    //   );

    //   if (!confirm) return;

    //   this.isLoading = true;
    //   var location = await getCurrentLocation(this);

    //   var res = await findStoreByLocation({
    //     type:'out',
    //     location: location
    //   });
    //   this.state = "checkout";
    //   this.stores = res.data;
    //   this.isLoading = false;
    //   this.storesDialogVisible = true;
    // },
    fetchData() {
      this.isLoading = true;
      getWorkShiftInfo({ id: this.sId }).then(res => {
        this.shift = res.data;
        this.isLoading = false;
      });
    }
  },
  computed: {
    sId() {
      return this.$route.params.id;
    }
  },
  data() {
    return {
      isLoading: false,
      storesDialogVisible: false,
      shift: {},
      stores: [],
      state: null
    };
  }
};
</script>
<style lang="css" scoped>
.line-break {
  text-overflow: unset;
  white-space: unset;
}
</style>