<template>
  <v-card>
    <v-card-title class="headline grey lighten-2" primary-title>Danh sách cửa hàng</v-card-title>

    <v-list flat>
      <v-list-item-group v-model="store" color="primary">
        <v-list-item v-for="(store, i) in stores" :key="i">
          <v-list-item-icon>
            <v-icon small>fas fa-store-alt</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title style="    font-size: 14px;" v-text="store.store_name"></v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-list-item-title style="    font-size: 12px;">{{store.distance|numberF }}m</v-list-item-title>
          </v-list-item-action>
        </v-list-item>
      </v-list-item-group>
    </v-list>

    <v-divider></v-divider>

    <v-card-actions >
      <v-spacer></v-spacer>
      <v-btn color="warning" @click="cancel()">Hủy</v-btn>
      <v-btn v-if="store !==  null&&store !==  undefined" color="primary" @click="pickStoreClick()">Chọn {{stores[store].store_name}}</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  data() {
    return {
      store: null
    };
  },
  watch: {
    stores(){
      this.store = null;
    }
  },
  methods:{
      pickStoreClick(){
          this.$emit('storeSlected',this.stores[this.store]);
      },
      cancel(){
          this.$emit('cancel');
      }
  },
  props: {
    stores: Array
  }
};
</script>