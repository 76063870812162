
function getCurrentLocation(context) {
    return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition((position)=>{
            resolve({
                coords: {
                    accuracy: position.coords.accuracy,
                    altitude: position.coords.altitude,
                    altitudeAccuracy: position.coords.altitudeAccuracy,
                    heading: position.coords.heading,
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                    speed: position.coords.speed,
                },
                timestamp: position.timestamp,
            })
        }, ({ code, message }) => {

            context.$toast.open({
                message: message,
                type: 'error',
                position: 'top-right',
                duration: 3000
                // all other options
            });
            reject(Object.assign(new Error(message), { name: "PositionError", code }))
        },
            {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0
            });

    });
};



module.exports = {
    //  BASE_API:'http://103.35.64.5:8086/api/v1/',

    getCurrentLocation: getCurrentLocation,
}